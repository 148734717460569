<template>
  <el-dialog
    append-to-body
    width="500px"
    :title="`${isChange ? '编辑专供' : '创建专供'}`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    >
      <el-form
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom'
        label-width='120px'
        label-position='right'
        :model='form.data'
        :rules='form.rules'
        :size="subUnitSize"
      >
        <el-form-item label="供应商/采购员" prop="type">
          <el-radio-group v-model="form.data.type" @change="handleType" v-removeAriaHidden>
            <el-radio :label="1">供应商</el-radio>
            <el-radio :label="2">采购员</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="客户名称" prop="merchant_id">
          <el-select v-model="form.data.merchant_id" filterable placeholder="请选择" @change="changeMerchant" class="width100">
            <el-option
              v-for="item in merchantArr"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="食堂名称" prop="address_id">
          <el-select v-model="form.data.address_id" filterable placeholder="请选择" class="width100">
            <el-option
              v-for="item in storeArr"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="供应商" prop="supplier_id" v-if="form.data.type == 1">
          <el-select v-model="form.data.supplier_id" filterable class="width100">
            <el-option v-for="item in supplierOpt" :label="item.name" :value="item.id" :key="item.id">
              <span style="float: left">{{ item.name }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.type == 1 ? '供应商' : '采购员' }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="采购员" prop="supplier_id" v-if="form.data.type == 2">
          <el-select v-model="form.data.supplier_id" filterable class="width100">
            <el-option v-for="item in purchaseOpt" :label="item.name" :value="item.id" :key="item.id">
              <span style="float: left">{{ item.name }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.type == 1 ? '供应商' : '采购员' }}</span>
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
     
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import common from '@/util'
  import { mixinTable } from '@/mixins/table.js'
  export default {
    name: 'SoleEdit',
    props:['tableData'],
    mixins: [mixinTable],
    data() {
      return {
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            id: '',
            type: 1,
            merchant_id: "", //客户名称
            supplier_id: '',// 供应商/采购员
            address_id: '', // 食堂id
          },
          rules: {
            merchant_id: [{ required: true, message:'请选择', trigger: 'change'}],
            supplier_id: [{ required: true, message:'请选择', trigger: ['blur','change']}],
          }
        },
        merchantArr: [],  // 客户
        storeArr: [], // 食堂
        supplierOpt: [], // 供应商
        purchaseOpt: [], // 采购员
      }
    },
    methods: {
      getDetail(row) {
        this.getMerchant();// 客户
        this.getSupplier();// 供应商
        this.getPurchase();// 采购员
        if(!!row.id) {
          this.isChange = true
          this.$nextTick(() => {
            common.deepClone(this.form.data, row)
            this.form.data.address_id = row.address_id != 0 ? row.address_id : '' ;
            this.form.data.area = [row.province, row.city];
            if(!!this.form.data.merchant_id) {
              this.getStore(); // 食堂 
            }
          })
        }
      },
      // 获取客户列表
      getMerchant() {
        // type 1:列表 2:参数
        this.$http.get("/admin/group/merchantList", {params:{type:1,page:1,count:10000}}).then((res) => {
          if(res.code == 1) {
            this.merchantArr = res.data.list;
          }
        })
      },
      // 客户的食堂列表
      getStore() {
        this.$http.get('/admin/path/storeList', {params: {page:1,count:10000,merchant_id:this.form.data.merchant_id}}).then(res => {
          if(res.code === 1) {
            this.storeArr = res.data.list;
          }
        })
      },
      // 选择客户查询后先清空食堂
      changeMerchant() {
        this.form.data.address_id = '';
        this.getStore(); // 查询列表
      },
      // 获取供应商 (启用中的供应商)
      getSupplier() {
        this.$http.get('/admin/supplier/list', {params:{status:1, page:1, count:10000}}).then(res => {
          if(res.code === 1) {
            this.supplierOpt = res.data.list;
          }
        })
      },
      // 获取采购员
      getPurchase() {
        this.$http.get('/admin/purchase/list', {params: {page:1, count:10000}}).then(res => {
          if(res.code === 1) {
            this.purchaseOpt = res.data.list;
          }
        })
      },
      // 切换供应商/采购员
      handleType() {
        this.form.data.supplier_id = '';
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            // // 判断客户在列表中是否已有相同的客户
            // let arr1 = this.tableData.filter(v => v.merchant_id == this.form.data.merchant_id);
            // let arr2 = []
            // // 如果选择了食堂，判断列表中是否已有相同的食堂
            // if(!!this.form.data.address_id && this.form.data.address_id != 0) {
            //   arr2 = this.tableData.filter(v => v.address_id == this.form.data.address_id);
            // }
            // // 编辑时若选择的供应商或食堂，列表中是否已有
            // if(!!this.isChange && (arr1.length > 0 || arr2.length > 0) ) {
            //   this.$confirm(`该客户/食堂绑定的供应商与其他供应商的商品存在重复，修改后需重新绑定商品，确定修改吗?`, '提示', {
            //     confirmButtonText: '确定',
            //     cancelButtonText: '取消',
            //     type: 'warning'
            //   }).then(() => {
            //     this.confirmFun();
            //   }).catch(() => {});
            // } else {
            //   this.confirmFun();
            // }
            this.confirmFun();
          }
        })
      },
      confirmFun() {
        let _params = {
          id: this.form.data.id,
          merchant_id: this.form.data.merchant_id,
          supplier_id: this.form.data.supplier_id,
          address_id: this.form.data.address_id,
        };
        let apiUrl = this.isChange ? '/admin/bind/edit' : '/admin/bind/add'
        this.form.loading = true;
        this.$http.post(apiUrl, _params).then(res => {
          if(res.code === 1) {
            if(!this.isChange) {
              this.$message({
                type: 'success',
                duration:5000, // 5秒后自动关闭
                message: '操作成功，请点击绑定商品确认专供商品'
              })
            }else {
              this.$message.success("操作成功！")
            }
            this.toggle(false);
            this.$emit('refresh')
          }
        }).finally(() => {
          this.form.loading = false;
        })
      }
    }
  }
</script>
