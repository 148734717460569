<template>
  <el-dialog
    append-to-body
    width="500px"
    title=""
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    >
      <el-alert
        style="margin-bottom:10px"
        title="温馨提示：将本供应商/采购员绑定客户专供的商品复制给其他客户"
        type="warning"
        :closable="false">
      </el-alert>
      <el-form
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom'
        label-width='120px'
        label-position='right'
        :model='form.data'
        :rules='form.rules'
        :size="subUnitSize"
      >
        <el-form-item label="供应商/采购员:">
          <span>{{form.data.supplier_name}}</span>
        </el-form-item>
        <el-form-item label="专供商品:">
          <span>{{num}}</span>
        </el-form-item>
        <el-form-item label="复制给客户" prop="merchant_id">
          <el-select v-model="form.data.merchant_id" filterable placeholder="请选择" @change="changeMerchant" class="width100">
            <el-option
              v-for="item in merchantArr"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="食堂名称" prop="address_id">
          <el-select v-model="form.data.address_id" filterable placeholder="请选择" class="width100">
            <el-option
              v-for="item in storeArr"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
     
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import { mixinTable } from '@/mixins/table.js'
  export default {
    name: 'SoleCopy',
    mixins: [mixinTable],
    data() {
      return {
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            supplier_id: '',// 供应商/采购员id
            merchant_id: "", //客户名称id
            address_id: '', // 食堂id
            goods_id: '', // 商品id
          },
          rules: {
            merchant_id: [{ required: true, message:'请选择', trigger: 'change'}],
            supplier_id: [{ required: true, message:'请选择', trigger: ['blur','change']}],
          }
        },
        num: 0,
        merchantArr: [],  // 客户
        storeArr: [], // 食堂
      }
    },
    methods: {
      getDetail(row) {
        this.getMerchant();// 客户
        if(!!row.id) {
          this.isChange = true
          this.form.data.supplier_id = row.supplier_id; // 供应商/采购员id
          this.form.data.supplier_name = row.supplier_name; // 供应商/采购员名称
          this.form.data.goods_id = row.goods_id; // 商品id
          this.num = row.goods_count.length; // 专供商品数量
        }
      },
      // 获取客户列表
      getMerchant() {
        // type 1:列表 2:参数
        this.$http.get("/admin/group/merchantList", {params:{type:1,page:1,count:10000}}).then((res) => {
          if(res.code == 1) {
            this.merchantArr = res.data.list;
          }
        })
      },
      // 客户的食堂列表
      getStore() {
        this.$http.get('/admin/path/storeList', {params: {page:1,count:10000,merchant_id:this.form.data.merchant_id}}).then(res => {
          if(res.code === 1) {
            this.storeArr = res.data.list;
          }
        })
      },
      // 选择客户查询后先清空食堂
      changeMerchant() {
        this.form.data.address_id = '';
        this.getStore(); // 查询列表
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            this.confirmFun();
          }
        })
      },
      confirmFun() {
        let _params = {
          supplier_id: this.form.data.supplier_id, // 供应商/采购员id
          merchant_id: this.form.data.merchant_id, // 客户id
          address_id: this.form.data.address_id, // 食堂id
          goods_id: this.form.data.goods_id, // 商品id （传列表上返回的商品id）
        };
        this.form.loading = true;
        // 复制功能调添加接口
        this.$http.post('/admin/bind/add', _params).then(res => {
          if(res.code === 1) {
            this.$message.success("操作成功！")
            this.toggle(false);
            this.$emit('refresh')
          }
        }).finally(() => {
          this.form.loading = false;
        })
      }
    }
  }
</script>
