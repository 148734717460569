<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="客户名称" prop="merchant_id">
          <el-select v-model="table.params.merchant_id" filterable id="bind_id" clearable placeholder="请输入" @focus="getMerchantSel" @change="onSearch">
            <el-option v-for="item in merchantArr" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="供应商名称" prop="supplier_id">
          <el-select v-model="table.params.supplier_id" filterable id="bind_id" clearable placeholder="请输入" @focus="getSupplier" @change="onSearch">
            <el-option v-for="item in supplierArr" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch">搜索</el-button>
          <el-button icon="el-icon-refresh-right" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btnAdd">
        <el-button :size="subUnitSize" type="primary" icon="el-icon-plus" @click="showEdit">创建</el-button>
      </div>
    </div>
    
    <VTable
      has-pagionation
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="false"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:goods_count="{row}">
        <el-link style="padding: 0 10px;" type="primary" @click="selGoods(row)">{{row.goods_count.length}}</el-link>
      </template>
     
      <template v-slot:action="{row}">
        <el-button type="text" v-if="row.goods_count == 0" @click="showEdit(row)">编辑</el-button>
        <el-button type="text" @click="remove(row)">删除</el-button>
        <el-button type="text" @click="showCopy(row)">复制</el-button>
      </template>
    </VTable>

    <!-- 编辑 -->
    <edit ref="edit" :tableData="table.data" @refresh="refreshData"></edit>
    <!-- 复制 -->
    <copy ref="copy" @refresh="refreshData"></copy>
    <!-- 绑定供货商品 -->
    <SelGoods
      :dialogTitle="dialogTitle"
      ref="selGoods"
      :getDataApi="getDataApi"
      :setDataApi="setDataApi"
      :showBind="showBind"
      :sourceType="sourceType"
      @refresh="getTable">
    </SelGoods>

  </div>
</template>

<script>
import Edit from './components/Edit.vue'
import copy from './components/Copy.vue'
import SelGoods from '@/components/SelGoods.vue'
import VTable from '@/components/VTable';
import { mixinTable } from '@/mixins/table.js'
import { mapGetters } from 'vuex'

export default {
  name: 'SupplierIndex',
  components: {
    Edit,
    copy,
    SelGoods,
    VTable,
  },
  mixins: [mixinTable],
  computed:{
    ...mapGetters(['areaArr'])
  },
  data() {
    return {
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      getDataApi: '/admin/bind/goodsList',
      setDataApi: '/admin/bind/edit',
      showBind: false,
      sourceType: 3, // 一品多商
      field: [
        { name: "id", label: "ID", width:'80', hidden: false },
        { name: "merchant_name", label: "客户名称", hidden: false },
        { name: "store_name", label: "食堂名称", hidden: false },
        { name: "supplier_name", label: "供应商/采购员", hidden: false},
        { name: "goods_count", label: "绑定商品",  hidden: false},
        { name: "create_time", label: "创建时间",  hidden: false},
        { name: "action", label: "操作",width: "140", fixed:"right", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          merchant_id: '',
          supplier_id: '',
          page: 1,
          count: 20
        },
        data: [],
        total: 0,
      },
      dialogTitle: '负责供货商品',
      message: false,
      merchantArr: [],
      supplierArr: [],
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      this.table.loading = true;
      this.$http.get('/admin/bind/list', {params:this.table.params}).then(res => {
        if(res.code === 1) {
          this.table.loading = false;
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        }
      })
    },
    // 获取供应商
    getSupplier() {
      this.$http.get('/admin/supplier/list', {params:{page:1, count:10000}}).then(res => {
        if(res.code === 1) {
          this.supplierArr = res.data.list;
        }
      })
    },
    // 新增、编辑
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 复制
    showCopy(row) {
      let dom = this.$refs.copy;
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    //负者供货商品
    selGoods(row) {
      let dom = this.$refs.selGoods
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 删除
    remove(row) {
      this.setConfirm(
        `确认删除操作？`,
        {  id: row.id },
        "/admin/bind/delete"
      );
    },
    refreshData() {
      this.getTable()
    }
  }
}
</script>
